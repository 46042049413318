<template>
  <div class="col aic">
    <img
      src="http://huaxiayongdao.oss-cn-beijing.aliyuncs.com/2022/02/11/022a3babd6e14942aab873f953c8d386.png"
      alt=""
    />
    <div>登录成功</div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
