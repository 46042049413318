<template>
  <div class="login">
    <el-card class="box-card text-center">
      <!-- 微信登录 -->
      <div v-if="showLoginType === 'wx'">
        <div class="title">快速安全登录</div>
        <div class="subtitle">请确认授权后使用微信扫码登录</div>
        <div class="wxlogin row juc">
          <!-- <div
            v-if="wxInfo.unionid"
            @click="login('wx', null)"
            class="qrcode avatarLogin"
          >
            <img :src="wxInfo.avatar" alt="" />
          </div> -->
            <div style="width: 180px;height: 180px;margin-bottom: 10px;margin-top: 10px;"
            class="qrcode avatarLogin"
            v-if="!checked"
            >
            <img src="@/assets/image/saoma.png" />
            </div>
          <div v-if="checked" @click="geturl()" class="qrcode">
            <qrcode-vue
              :margin="0"
              :value="
                checked
                  ? qrcodeUrl
                  : '请确认下并同意《用户服务协议》和《隐私政策》协议后登录'
              "
              :size="120"
              level="L"
            ></qrcode-vue>
          </div>
        </div>
      </div>
      <!-- 手机登录 -->
      <el-form
        :rules="rules"
        class="phoneLogin"
        ref="phoneRef"
        v-if="showLoginType === 'phone'"
        :model="phoneForm"
      >
        <el-form-item>
          <div class="title">手机号登录</div>
        </el-form-item>
        <el-form-item prop="value">
          <el-input
            class="ma-to-30"
            v-model="phoneForm.value"
            placeholder="输入手机号"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input  style="width: 214px;" v-model="phoneForm.code" placeholder="输入验证码" />
          <auth-code
            text="发送"
            ref="code"
            class="auth-code"
            btnClass="code-btn"
            second="40"
            @start="start(phoneForm.value, 'phoneCode')"
          ></auth-code>
        </el-form-item>
        <el-form-item>
          <el-button @click="login('phone', phoneRef)">登录</el-button>
        </el-form-item>
      </el-form>
      <!-- 邮箱登录 -->
      <el-form
        :rules="rules"
        ref="emaliRef"
        class="emaliLogin"
        v-if="showLoginType === 'emali'"
        :model="emaliForm"
      >
        <el-form-item>
          <div class="title">邮箱登录</div>
        </el-form-item>
        <el-form-item prop="value">
          <el-input
            class="ma-to-30"
            v-model="emaliForm.value"
            placeholder="输入邮箱"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input style="width: 214px;" v-model="emaliForm.code" placeholder="输入验证码" />
          <auth-code
            text="发送"
            ref="code"
            class="auth-code"
            btnClass="code-btn"
            second="60"
            @start="start(emaliForm.value, 'emailCode')"
          ></auth-code>
        </el-form-item>
        <el-form-item>
          <el-button @click="login('emali', emaliRef)">登录</el-button>
        </el-form-item>
      </el-form>
      <!-- 邮箱登录 -->
      <el-form
        :rules="rules"
        ref="accountRef"
        class="accountLogin"
        v-if="showLoginType === 'account'"
        :model="accountForm"
      >
        <el-form-item>
          <div class="title">账号登录</div>
        </el-form-item>
        <el-form-item prop="value">
          <el-input
            class="ma-to-30"
            v-model="accountForm.value"
            placeholder="输入账号"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="accountForm.password"
            type="password"
            show-password
            placeholder="输入密码"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="accountForm.code"
            auto-complete="off"
            placeholder="验证码"
            style="width: 65%; margin-right: auto"
          />
          <div class="login-code">
            <img :src="codeUrl" @click="getCode" class="login-code-img" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="login('account', accountRef)">登录</el-button>
        </el-form-item>
      </el-form>
      <!-- 同意 -->
      <div>
        <div
          class="ma-to-30 animate__animated row aic juc"
          :class="{ animate__shakeX: !checked }"
        >
          <el-checkbox v-model="checked">
            <template #> 我已阅读并同意 </template>
          </el-checkbox>
          <p class="inline">
            <span class="agreement" @click="showAgreement(2)"
              >《用户服务协议》</span
            >和
            <span class="agreement" @click="showAgreement(1)"
              >《隐私政策》</span
            >
          </p>
        </div>
        <div class="ma-to-30 text-center">
          <el-divider content-position="center"> 其他登录方式 </el-divider>
        </div>
        <!-- 方式切换 -->
        <div class="row sa ma-tb-30" style="color: #606266">
          <div class="pointer" @click="loginMode('wx')">微信登录</div>
          <div class="pointer" @click="loginMode('phone')">手机号登录</div>
          <div class="pointer" @click="loginMode('emali')">邮箱登录</div>
          <div class="pointer" @click="loginMode('account')">账号登录</div>
        </div>
      </div>
    </el-card>
  </div>
  <el-dialog
    v-model="dialogVisible"
    :title="showContent.articleTitle"
    width="900px"
    :show-close="false"
    :close-on-click-modal="false"
    center
  >
  <div     style=" padding-left: 30px;
    padding-right: 30px;" v-html="showContent.articleContent"></div>
  <template #footer  >
      <span class="dialog-footer" >
        <el-button color="#6e7eb8" type="primary" @click="closeDia()"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import AuthCode from '@/components/AuthCode'
import QrcodeVue from 'qrcode.vue'
import Cookies from 'js-cookie'
import { regexEmail, regexPhone, numBer, regexChinas } from '@/common/regexp'
import {
  getCodeImg,
  getPhoneCode,
  getEmailCode,
  getWXLoginUrl,
  getToken
} from '@/api/login'
import { getEssayDetail } from '../../api/index'
import { encrypt } from '@/utils/jsencrypt'
import { getItem, setItem } from '../../utils/storage'
import moment from 'moment'
const checked = ref(false)
const arId = ref('')
const showContent = ref('')
const dialogVisible = ref(false)
const showLoginType = ref('wx')
const codeUrl = ref('')
const store = useStore()
const router = useRouter()
const qrcodeUrl = ref('')
const phoneForm = ref({
  value: '',
  code: ''
})
let wx = getItem('userInfo') || {
  avatar: require('../../assets/icon/default_avatar.png')
}

var state = ''
const wxInfo = ref(wx)
const emaliForm = ref({
  value: '',
  code: ''
})
const accountForm = ref({
  value: '',
  password: '',
  code: ''
})
const validator = ({ field }, value, callback) => {
  if (field === 'value') {
    if (!value) {
      callback(new Error('不可以为空'))
    }
    if (showLoginType.value === 'phone') {
      regexPhone.test(value)
        ? callback()
        : callback(new Error('手机号格式错误'))
    }
    if (showLoginType.value === 'emali') {
      regexEmail.test(value) ? callback() : callback(new Error('邮箱格式错误'))
    }
  }
  if (field === 'password') {
    const regmima = /.{6,18}/
    regexChinas.test(value)
      ? callback(new Error('密码不可以包含中文'))
      : regmima.test(value)
      ? callback()
      : callback(new Error('长度在6~18之间'))
  }
  if (field === 'code') {
    numBer.test(value)
      ? callback()
      : callback(new Error('只能是数字，不能含有有空格'))
  }
}
const rules = ref({
  value: [
    {
      required: true,
      trigger: ['change'],
      validator
    }
  ],
  reg: [
    {
      required: true,
      trigger: ['change'],
      validator
    }
  ],
  password: [
    {
      required: true,
      trigger: ['change'],
      validator
    }
  ]
})
const code = ref(null)
const phoneRef = ref(null)
const emaliRef = ref(null)
const accountRef = ref(null)
const loginForm = ref({})
const redirect = router.currentRoute.value.query.redirect
// 协议
const showAgreement = (articleId) => {
  getEssayDetail({ articleId }).then((res) => {
    if(articleId==4){
      if(res.total==0){
        router.push({ path: redirect || '/' })
        return
      }
    }
    showContent.value = res.rows[0]
    dialogVisible.value = true
    arId.value = articleId;
  })
}
const closeDia = () =>{
  dialogVisible.value = false
  if(arId.value==4){
    router.push({ path: redirect || '/' })
  }
}
// 登录模式
const loginMode = (v) => {
  showLoginType.value = v
}
const geturl = () => {
  getWXLoginUrl({ scope: 'snsapi_userinfo' }).then((res) => {
    state = res.data.state
    qrcodeUrl.value = res.data.url
  })
}
geturl()
const getCode = async () => {
  var res = await getCodeImg()
  codeUrl.value = 'data:image/gif;base64,' + res.data.img
  loginForm.value.uuid = res.data.uuid
}
const loginEnd = () => {
  let end = getItem('loginTime')
  let endM = null
  if (end) {
    endM = moment(end).month()
  }
  var currentTime = moment(Date.now()).month()
  let hint = endM == null ? true : currentTime > endM
  console.log(hint)
          showAgreement(4);
  // if (hint && getItem('token')) {
  //       showAgreement(4);
  //        setItem('loginTime', Date.now())
  // } else if (getItem('token')) {
  //   router.push({ path: redirect || '/' })
  // }
}
getCode()
let loginTime = null
const createTime = () => {
  loginTime = setInterval(() => {
    getToken({ state }).then((res) => {
      if (res.data.token) {
        store.commit('user/setToken', res.data.token)
        clearInterval(loginTime)
        loginEnd()
      }
    })
  }, 3000)
}

const clearTime = () => {
  clearInterval(loginTime)
}
// 登录
const login = (type) => {
  if (checked.value) {
    var data
    if (showLoginType.value === 'account') {
      accountRef.value.validate((valid) => {
        if (!valid) return valid
      })
      if (loginForm.value.rememberMe) {
        Cookies.set('username', loginForm.value.username, { expires: 30 })
        Cookies.set('password', encrypt(loginForm.value.password), {
          expires: 30
        })
        Cookies.set('rememberMe', loginForm.value.rememberMe, {
          expires: 30
        })
      } else {
        Cookies.remove('username')
        Cookies.remove('password')
        Cookies.remove('rememberMe')
      }
      data = {
        methd: 'account',
        username: accountForm.value.value,
        password: accountForm.value.password,
        code: accountForm.value.code,
        uuid: loginForm.value.uuid
      }
    } else if (showLoginType.value === 'wx') {
      clearTime()
      data = {
        methd: 'wx'
      }
    } else if (showLoginType.value === 'phone') {
      data = {
        methd: 'phone',
        phone: phoneForm.value.value,
        code: phoneForm.value.code
      }
    } else if (showLoginType.value === 'emali') {
      data = {
        methd: 'emali',
        mail: emaliForm.value.value,
        code: emaliForm.value.code
      }
    } else {
      return ElMessage({
        message: '登录失败',
        type: 'error'
      })
    }
    store
      .dispatch('user/login', data)
      .then(() => {
        loginEnd()
      })
      .catch(() => {
        showLoginType.value === 'account' && getCode()
      })
  } else {
    checked.value = true
    setTimeout(() => (checked.value = false), 0)
  }
}
const startMessage = (res) => {
  if (res?.data) {
    return ElMessage({
      message: '发送成功',
      type: 'success'
    })
  } else if (!res.data) {
    return ElMessage({
      message: '发送失败',
      type: 'error'
    })
  }
}
const start = async (params, m) => {
  var res
  if (m === 'phoneCode') {
    if (regexPhone.test(params)) {
      res = await getPhoneCode({ phone: params })
      startMessage(res)
    } else {
      ElMessage({
        message: '手机号格式错误',
        type: 'error'
      })
    }
  } else if (m === 'emailCode') {
    if (regexEmail.test(params)) {
      res = await getEmailCode({ addressee: params })
      startMessage(res)
    } else {
      ElMessage({
        message: '邮箱格式错误',
        type: 'error'
      })
    }
  }
}

onBeforeUnmount(() => {
  clearInterval(loginTime)
})
watch(
  () => showLoginType.value,
  (val) => {
    if (val === 'wx') createTime()
    else clearTime()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
@import '@/common/rem';
.title,
.subtitle {
  line-height: 2;
}
.login {
  padding: rem(100) 0;
  font-size: rem(16);
  // font-weight: bold;
  background: #f3f5f7;
  min-width: 1200px;
  min-height: calc(100vh - (70px + 260px + 200px));
  .box-card {
    margin: auto;
    width: rem(540);
    border-radius: rem(20);
    background: #ffffff;
    .qrcode {
      $balck: 120;
      margin: rem(30);
      width: rem($balck);
      height: rem($balck);
    }
    .el-button {
      width: 100%;
      font-weight: bold;
      color: #ffffff;
      background: rgb(41, 62, 115);
    }
    .phoneLogin,
    .emaliLogin,
    .accountLogin {
      width: rem(300);
      margin: 0 auto;
      .auth-code {
        margin: auto;
        width: rem(70);
        right: rem(0.5em);
        position: absolute;
        // top: calc(50% - 1.3em);
      }
      .code-btn {
        width: auto;
        padding: 0;
        color: #293e73;
        background: #ffffff;
      }
    }
  }
}
.avatarLogin {
  // background: #293e73;
  img {
    width: 100%;
    height: 100%;
  }
}

.login-code {
  width: 33%;
  // height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.login-code-img {
  height: 32px;
}
.inline {
  font-size: rem(14);
  font-weight: 400;
  .agreement {
    color: #293e73;
    cursor: pointer;
  }
}
::v-deep .el-message-box {
  width: 50% !important;
}
.el-dialog__body{
      padding-left: 50px;
    padding-right: 50px;
}

</style>
